<template>
  <v-tabs
    grow
    show-arrows
    v-model="tab"
    background-color="grey"
    color="white"
  >
    <v-tab
      v-for="d in disciplines"
      :key="d._id"
      :href="`#tab-${d._id}`"
    >
      {{ d.order }}. {{ d.name }}
    </v-tab>

    <v-tab-item
      v-for="d in disciplines"
      :key="d._id"
      :value="`tab-${d._id}`"
    >
      <v-container fluid>
        <h2>Kampfgericht ({{ d.name }})</h2>
        <v-row>
          <v-col cols="12">
            <base-edit-dialog-personsuche
              :value="getJudge('d1', d._id)"
              :label="`Kampfrichter D1`"
              :id="d._id"
              clearable
              @input="({value, id}) => save('d1', value, id)"
            />
          </v-col>
          <v-col cols="12">
            <base-edit-dialog-personsuche
              :value="getJudge('d2', d._id)"
              :label="`Kampfrichter D2`"
              :id="d._id"
              clearable
              @input="({value, id}) => save('d2', value, id)"
            />
          </v-col>
          <v-col cols="12">
            <base-edit-dialog-personsuche
              :value="getJudge('e1', d._id)"
              :label="`Kampfrichter E1`"
              :id="d._id"
              clearable
              @input="({value, id}) => save('e1', value, id)"
            />
          </v-col>
          <v-col cols="12">
            <base-edit-dialog-personsuche
              :value="getJudge('e2', d._id)"
              :label="`Kampfrichter E2`"
              :id="d._id"
              clearable
              @input="({value, id}) => save('e2', value, id)"
            />
          </v-col>
          <v-col cols="12">
            <base-edit-dialog-personsuche
              :value="getJudge('e3', d._id)"
              :label="`Kampfrichter E3`"
              :id="d._id"
              clearable
              @input="({value, id}) => save('e3', value, id)"
            />
          </v-col>
          <v-col cols="12">
            <base-edit-dialog-personsuche
              :value="getJudge('e4', d._id)"
              :label="`Kampfrichter E4`"
              :id="d._id"
              clearable
              @input="({value, id}) => save('e4', value, id)"
            />
          </v-col>
        </v-row>
        <h2>Hinweise:</h2>
        <ul>
          <li>Wichtig für den Wettkampf ist, dass die eingegebene Anzahl der Abzugs-Kampfrichter stimmt!</li>
          <li>Es können aktuell noch keine neuen Personen im System angelegt werden.</li>
          <li>Soll eine Person als Kampfrichter genutzt werden, die noch nicht im System hinterlegt ist, so eine andere Person eingeben und eine E-Mail an <a href="mailto:ergebnisdienst@stb.de">ergebnisdienst@stb.de</a> schreiben!</li>
        </ul>
      </v-container>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'

export default {
  name: 'kampfgerichte',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    tab: 0
  }),

  computed: {
    disciplines () {
      const tmp = (this.df?.map(d => ({
        ...d,
        __typename: undefined,
        active: !!this.e?.disciplines?.find(ed => ed._discipline === d._id),
        order: this.e?.disciplines?.find(ed => ed._discipline === d._id)?.order
      })) || [])
        .filter(d => d.active && d.regular)
        .sort((a, b) => {
          return a.order < b.order ? -1 : 1
        })
      return tmp
    }
  },

  methods: {
    save (type, person, discipline) {
      if (person !== null) {
        this.mutate({
          mutation: gql`mutation($id: UUID!, $type: StbW2023WkJudgeType!, $person: UUID!, $discipline: UUID!) {
            StbW2023WkJudgeAdd(id: $id, type: $type, person: $person, discipline: $discipline) { _id }
          }`,
          variables: {
            id: this.id,
            type,
            person,
            discipline: discipline === 'null' ? null : discipline
          }
        })
      } else {
        this.mutate({
          mutation: gql`mutation($id: UUID!, $type: StbW2023WkJudgeType!, $discipline: UUID!) {
            StbW2023WkJudgeDelete(id: $id, type: $type, discipline: $discipline) { _id }
          }`,
          variables: {
            id: this.id,
            type,
            discipline: discipline === 'null' ? null : discipline
          }
        })
      }
    },
    getJudge (type, discipline) {
      if (discipline === 'null') discipline = null
      const judge = this.e?.judges?.find(j => j.type === type && j._discipline === discipline)
      return judge?.person ? `${judge.person.familyName}, ${judge.person.givenName}` : null
    },
    getClub (type, discipline) {
      if (discipline === 'null') discipline = null
      const judge = this.e?.judges?.find(j => j.type === type && j._discipline === discipline)
      return judge?.club ? judge.club.name : null
    }
  }
}
</script>

<style scoped>

</style>
